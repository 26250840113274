import { tv } from 'tailwind-variants';

export const ButtonVariants = tv({
  base: 'rounded-[42px] hover:bg-primary-40 inline-flex items-center justify-center py-4 px-8 text-base transition hover:opacity-70',
  variants: {
    variant: {
      primary: 'bg-primary-40 text-neutral-90 hover:bg-[#3FF49B]',
      secondary: 'bg-transparent text-white',
      tertiary: 'hover:bg-transparent hover:text-white hover:underline bg-transparent',
      quaternary: 'bg-white hover:bg-black hover:text-white',
      quinary: 'bg-transparent text-black border border-white hover:bg-white',
      senary: 'border border-black text-black hover:bg-white',
    },
  },
});
