import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Link from 'next/link';
import Mexico from '@/assets/br/sections/navbar/mexico.png';
import Brasil from '@/assets/br/sections/navbar/brasil.png';
import { LanguageSwitcherProps } from './language-witcher-interfaces';
import Image from 'next/image';

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ language, backgroundColor }) => {
  const textColor = backgroundColor === 'bg-white' ? 'text-black' : 'text-white';

  return (
    <NavigationMenu.Item className="flex">
      <NavigationMenu.Trigger
        className={`group flex select-none items-center justify-between gap-2 text-xs leading-none  outline-none ${textColor}`}
      >
        <div className="inline-flex items-center gap-2">
          <Image
            src={language === 'BR' ? Brasil.src : Mexico.src}
            alt={language}
            title={language}
            width={16}
            height={16}
          />
          <span>{language}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          className="relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
        >
          <path
            d="M1.90234 1.89062L6.12109 6.10938L10.3398 1.89062"
            stroke={`${backgroundColor === 'bg-white' ? '#0E0E0E' : '#FFFFFF'}`}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="absolute top-14 w-full rounded-2xl bg-neutral-100 sm:w-auto">
        <ul className="one list-none flex-col items-center divide-y divide-neutral-70 px-4 py-2">
          <li className="py-2">
            <Link href="/mx/" className="inline-flex w-24 gap-4 text-center text-sm">
              <span>México</span>
            </Link>
          </li>
          <li className="py-2">
            <Link href="/br/" className="inline-flex w-24 gap-4 text-center text-sm">
              <span>Brasil</span>
            </Link>
          </li>
        </ul>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
};
