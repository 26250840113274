'use client';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { DesktopNavbar } from './desktop-navbar';
import { MobileNavbar } from './mobile-navbar';

import logoDesktopBlack from '@/assets/br/sections/navbar/totalpass-desktop-black.svg';
import logoDesktopWhite from '@/assets/br/sections/navbar/totalpass-desktop-white.svg';

import { MenuData } from '@/api/graphql/interfaces/menus.interfaces';

interface NavbarMenu {
  allMenu: MenuData;
}

export const Navbar: React.FC<NavbarMenu> = ({ allMenu }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const isMx = usePathname()?.includes('/mx/');

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(currentScrollPos < 100 || prevScrollPos > currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const isAtTop = prevScrollPos < 100;
  const backgroundClass = isAtTop ? '' : 'bg-white';
  const visibleClass = visible ? 'translate-y-0 transform opacity-100' : '-translate-y-full transform opacity-0';
  const logoTP = isAtTop ? logoDesktopWhite.src : logoDesktopBlack.src;

  return (
    <header className={`fixed z-40 w-full transition-all duration-500 ${backgroundClass} ${visibleClass}`}>
      <div className="container mx-auto flex min-h-[56px] items-center justify-between bg-transparent px-6 md:px-8">
        <div>
          <Link href={isMx ? '/mx/' : '/br/'}>
            <Image src={logoTP} alt="TotalPass" className="block" width={103} height={30} title="TotalPass" />
          </Link>
        </div>
        <DesktopNavbar backgroundColor={backgroundClass} menus={allMenu} />
        <MobileNavbar backgroundColor={backgroundClass} menus={allMenu} />
      </div>
    </header>
  );
};
