import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import * as Collapsible from '@radix-ui/react-collapsible';

import { LanguageSwitcherProps } from './language-witcher-interfaces';

import Mexico from '@/assets/br/sections/navbar/mexico.png';
import Brasil from '@/assets/br/sections/navbar/brasil.png';

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ language }) => {
  return (
    <Collapsible.Root>
      <Collapsible.Trigger
        asChild
        className="group flex w-full select-none items-center justify-between gap-2 text-sm leading-none text-white outline-none"
      >
        <button className="flex w-full items-center justify-between text-sm font-medium text-white lg:hidden">
          <div className="inline-flex items-center gap-2">
            <Image
              src={language === 'BR' ? Brasil.src : Mexico.src}
              alt={language}
              title={language}
              width={24}
              height={24}
            />
            <span>{language}</span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            className="relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
          >
            <path
              d="M1.90234 1.89062L6.12109 6.10938L10.3398 1.89062"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Collapsible.Trigger>
      <Collapsible.Content className="px-3 py-2 text-xs text-white">
        <ul className="flex flex-col py-2">
          <li className="inline-flex">
            <Link href="/mx/" className="inline-flex w-24 gap-4 text-center text-sm text-white hover:text-primary-40">
              <Image src={Mexico.src} alt="México" title="México" width={24} height={24} />
              <span>México</span>
            </Link>
          </li>
          <li className="inline-flex gap-4 py-6">
            <Link href="/br/" className="inline-flex w-24 gap-4 text-center text-sm text-white hover:text-primary-40">
              <Image src={Brasil.src} alt="Brasil" title="Brasil" width={24} height={24} />
              <span>Brasil</span>
            </Link>
          </li>
        </ul>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
