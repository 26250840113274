/* eslint-disable no-prototype-builtins */
'use client';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';

import { Button } from '@/components/ui/button';
import { LanguageSwitcher } from './desktop-language-switcher';

import { MenuData } from '@/api/graphql/interfaces/menus.interfaces';

interface NavbarProps {
  backgroundColor: string;
  menus: MenuData;
}

export const DesktopNavbar: React.FC<NavbarProps> = ({ backgroundColor, menus }) => {
  const locale = usePathname()?.includes('/mx/') ? 'MX' : 'BR';

  const data = menus;

  const header = data.menus[0].sections;

  const textColor = backgroundColor === 'bg-white' ? 'text-black' : 'text-white';

  const sections = header.map((section) => ({
    [section.slug]: section.items.map((item) => ({
      title: item.title,
      url: item.url,
      icon: item.icon,
      target: item.target,
      customIcon: item.customIcon?.url,
      rel: item.rel,
    })),
  }));

  const institutionalSection = sections.find((section) => section.hasOwnProperty('institutional'));
  const helpSection = sections.find((section) => section.hasOwnProperty('help'));

  return (
    <>
      <NavigationMenu.Root className="hidden lg:relative lg:mx-4 lg:flex lg:justify-start">
        <NavigationMenu.List className="flex list-none items-center gap-8 text-center">
          <NavigationMenu.Item className="flex gap-8">
            {institutionalSection &&
              institutionalSection['institutional'].map((item, index) => (
                <ul className="one flex items-center" key={index}>
                  <li className="py-6">
                    <Link href={item.url} rel={item.rel} className={`block text-center text-xs ${textColor}`}>
                      {item.title}
                    </Link>
                  </li>
                </ul>
              ))}
          </NavigationMenu.Item>
          {header.map((item, index) => {
            if (item.slug === 'aboutTotalpass')
              return (
                <NavigationMenu.Item className="flex" key={index}>
                  {'items' in item && (
                    <>
                      <NavigationMenu.Trigger
                        className={`group flex select-none items-center justify-between gap-2 text-xs leading-none outline-none ${textColor}`}
                      >
                        {item.title}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          className="relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
                        >
                          <path
                            d="M1.90234 1.89062L6.12109 6.10938L10.3398 1.89062"
                            stroke={`${backgroundColor === 'bg-white' ? '#0E0E0E' : '#FFFFFF'}`}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </NavigationMenu.Trigger>
                      <NavigationMenu.Content className="absolute w-full divide-y divide-neutral-70 rounded-2xl bg-neutral-100 px-4 py-2 sm:w-auto lg:top-16 xl:top-14">
                        {item.items.map((list, index) => (
                          <ul className="one list-none flex-col items-center" key={index}>
                            <li className="py-2">
                              <Link href={list.url} rel={list.rel} className="block text-left text-sm">
                                {list.title}
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </NavigationMenu.Content>
                    </>
                  )}
                </NavigationMenu.Item>
              );
          })}
          <NavigationMenu.Item className={`flex gap-4 ${textColor}`}>
            {helpSection &&
              helpSection['help'].map((item, index) => (
                <ul className="one flex items-center" key={index}>
                  <li className="py-6">
                    <Link href={item.url} rel={item.rel} className={`block text-center text-xs ${textColor}`}>
                      {item.title}
                    </Link>
                  </li>
                </ul>
              ))}
          </NavigationMenu.Item>
          <LanguageSwitcher backgroundColor={`${backgroundColor}`} language={locale} />
        </NavigationMenu.List>
      </NavigationMenu.Root>
      <NavigationMenu.Root className="hidden lg:relative lg:flex lg:justify-start">
        <NavigationMenu.List>
          {header.map((item, index) => {
            if (item.slug === 'signin')
              return (
                <NavigationMenu.Item key={index}>
                  {'items' in item ? (
                    <>
                      <NavigationMenu.Trigger
                        className={`group inline-flex select-none items-center justify-between gap-2  px-6 py-1 text-xs outline-none ${textColor}`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                          <path
                            d="M13.3327 14.295V12.9616C13.3327 12.2544 13.0517 11.5761 12.5516 11.076C12.0515 10.5759 11.3733 10.295 10.666 10.295H5.33268C4.62544 10.295 3.94716 10.5759 3.44706 11.076C2.94697 11.5761 2.66602 12.2544 2.66602 12.9616V14.295"
                            stroke={`${backgroundColor === 'bg-white' ? '#0E0E0E' : '#FFFFFF'}`}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.00065 7.6283C9.47341 7.6283 10.6673 6.43439 10.6673 4.96163C10.6673 3.48887 9.47341 2.29497 8.00065 2.29497C6.52789 2.29497 5.33398 3.48887 5.33398 4.96163C5.33398 6.43439 6.52789 7.6283 8.00065 7.6283Z"
                            stroke={`${backgroundColor === 'bg-white' ? '#0E0E0E' : '#FFFFFF'}`}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {item.title}
                      </NavigationMenu.Trigger>
                      <NavigationMenu.Content className="absolute -right-6 top-10 w-[150px] divide-y divide-neutral-70 rounded-2xl bg-neutral-100 px-4 py-2">
                        {item.items.map((list, index) => (
                          <ul className="one list-none flex-col" key={index}>
                            <li className="py-2">
                              <Link href={list.url} rel={list.rel} className="block text-xs">
                                {list.title}
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </NavigationMenu.Content>
                    </>
                  ) : (
                    <Button variant="secondary" className="px-6 py-1 text-xs font-normal">
                      Login
                    </Button>
                  )}
                </NavigationMenu.Item>
              );
          })}
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </>
  );
};
