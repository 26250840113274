/* eslint-disable no-prototype-builtins */
'use client';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { MenuData } from '@/api/graphql/interfaces/menus.interfaces';

import LogoTP from '@/assets/br/sections/navbar/totalpass-desktop-white.svg';

interface FooterMenu {
  allMenu: MenuData;
}

export const Footer: React.FC<FooterMenu> = ({ allMenu }) => {
  const isMx = usePathname()?.includes('/mx/');

  const data = allMenu;

  const [shouldRender, setShouldRender] = useState(true);

  const footer = data.menus[0].sections;

  const sections = footer.map((section) => ({
    [section.slug]: section.items.map((item) => ({
      title: item.title,
      url: item.url,
      icon: item.icon,
      target: item.target,
      customIcon: item.customIcon?.url,
      rel: item.rel,
    })),
  }));

  const socialMediaSection = sections.find((section) => section.hasOwnProperty('socialMedia'));
  const policiesSection = sections.find((section) => section.hasOwnProperty('policies'));
  const menuSection = sections.find((section) => section.hasOwnProperty('menuLeft'));
  const subMenuSection = sections.find((section) => section.hasOwnProperty('menuRight'));
  const storesSection = sections.find((section) => section.hasOwnProperty('stores'));
  const copyrigthSection = sections.find((section) => section.hasOwnProperty('copyright'));

  useEffect(() => {
    const handleResize = () => {
      setShouldRender(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const socialMediaItems =
    socialMediaSection &&
    socialMediaSection['socialMedia'].map((item, index) => (
      <div key={index}>
        <a href={item.url} target={item.target ? item.target : '_blank'} title={item.title} rel="noreferrer">
          <Image src={`${item.customIcon}`} alt={`${item.title}`} width={28} height={28} />
        </a>
      </div>
    ));

  const policiesItems =
    policiesSection &&
    policiesSection['policies'].map((item, index) => (
      <div key={index}>
        <a href={item.url} target={item.target ? item.target : '_blank'} title={item.title} rel="noreferrer">
          <p className="flex flex-col py-3 text-sm text-neutral-30">{item.title}</p>
        </a>
      </div>
    ));

  const subMenuSectionItems =
    subMenuSection &&
    subMenuSection['menuRight'].map((item, index) => (
      <div key={index}>
        <a href={item.url} target={item.target ? item.target : '_blank'} title={item.title} rel="noreferrer">
          <p className="text-xs text-neutral-30">{item.title}</p>
        </a>
      </div>
    ));

  const storesSectionItems =
    storesSection &&
    storesSection['stores'].map((item, index) => (
      <div key={index}>
        <a href={item.url} target={item.target ? item.target : '_blank'} title={item.title} rel="noreferrer">
          <Image src={`${item.customIcon}`} alt={`${item.title}`} width={139} height={41} />
        </a>
      </div>
    ));

  if (shouldRender) {
    return (
      <section data-testid="mobile-footer" className="mx-8 border-t-2 border-neutral-20 py-8">
        <div className="container">
          <div className="mb-4 space-y-2">
            {menuSection &&
              menuSection['menuLeft'].map((item, index) => (
                <div key={index}>
                  <a href={item.url} rel={item.rel} title={item.title}>
                    <p className="py-3 text-sm font-medium text-neutral-30">{item.title}</p>
                  </a>
                </div>
              ))}
            {subMenuSection &&
              subMenuSection['menuRight'].map((item, index) => (
                <div key={index}>
                  <a href={item.url} rel={item.rel} target={item.target ? item.target : '_self'} title={item.title}>
                    <p className="py-3 text-sm font-medium text-neutral-30">{item.title}</p>
                  </a>
                </div>
              ))}
          </div>
          <div className="mb-4 flex space-x-2">{socialMediaItems}</div>
          <div className="mb-4 flex flex-col">
            <p className="py-3 text-sm text-neutral-30">
              {isMx ? 'Descarga nuestra aplicación' : 'Baixe nosso aplicativo'}
            </p>
            <div className="flex space-x-2">{storesSectionItems}</div>
          </div>
          <div className="mb-5">{policiesItems}</div>
          <div className="mb-8">
            <p className="text-sm font-medium text-neutral-30">
              {copyrigthSection && copyrigthSection['copyright'].map((item) => item.title)}
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section data-testid="desktop-footer" className="flex justify-center p-8 md:border-t-2 md:border-neutral-20">
      <div className="container">
        <div className="flex gap-20">
          <div className="flex flex-col items-center space-y-6">
            <Link href="/br/">
              <Image src={LogoTP} alt="Logo Totalpass" title="Logo Totalpass" width={175} height={50} />
            </Link>
            <div className="mb-4 flex space-x-2">{socialMediaItems}</div>
          </div>
          <div className="flex flex-wrap">
            <div className="flex w-full flex-row gap-5">
              {menuSection &&
                menuSection['menuLeft'].map((item, index) => (
                  <div key={index}>
                    <a href={item.url} rel={item.rel} title={item.title}>
                      <p className="text-sm font-medium text-neutral-30">{item.title}</p>
                    </a>
                  </div>
                ))}
            </div>
            <div className="mt-4 flex flex-col items-start gap-2">{subMenuSectionItems}</div>
          </div>
        </div>
        <div className="my-6 flex items-center justify-end gap-2">
          <p className="text-sm text-neutral-30">{isMx ? 'Descarga nuestra aplicación' : 'Baixe nosso aplicativo'}</p>
          <div className="flex space-x-2">{storesSectionItems}</div>
        </div>
        <div className="mt-10 flex justify-center gap-6">{policiesItems}</div>
        <div className="mb-8 mt-3">
          <p className="text-center text-xs font-medium text-neutral-30">
            {copyrigthSection && copyrigthSection['copyright'].map((item) => item.title)}
          </p>
        </div>
      </div>
    </section>
  );
};
