/* eslint-disable no-prototype-builtins */
'use client';
import * as Collapsible from '@radix-ui/react-collapsible';
import * as Dialog from '@radix-ui/react-dialog';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';
import { LanguageSwitcher } from './mobile-language-switcher';

import { MenuData } from '@/api/graphql/interfaces/menus.interfaces';

import LogoTP from '@/assets/components/footer/logo-tp/logo-tp.svg';
import AppleStore from '@/assets/components/mobile-navbar/apple_store.svg';
import GooglePlay from '@/assets/components/mobile-navbar/google_play.svg';

interface NavbarProps {
  backgroundColor: string;
  menus: MenuData;
}

export const MobileNavbar: React.FC<NavbarProps> = ({ backgroundColor, menus }) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const locale = usePathname()?.includes('/mx/') ? 'MX' : 'BR';

  const data = menus;

  const handleNavigation = (path: string) => {
    setOpen(false);
    router.push(path, { scroll: false });
  };

  const handleMenuItemClick = (item: { url: string; items?: Array<{ title: string; url: string }> }) => {
    (item.items && item.items.length <= 0) ?? handleNavigation(item.url);
  };

  const header = data.menus[0].sections;

  const sections = header.map((section) => ({
    [section.slug]: section.items.map((item) => ({
      title: item.title,
      url: item.url,
      icon: item.icon,
      target: item.target,
      customIcon: item.customIcon?.url,
      rel: item.rel,
    })),
  }));

  const institutionalSection = sections.find((section) => section.hasOwnProperty('institutional'));
  const helpSection = sections.find((section) => section.hasOwnProperty('help'));

  return (
    <div className="lg:hidden">
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger asChild>
          <Button className="border-transparent px-0 py-0 lg:hidden" aria-label="menu principal">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 26 26" fill="none">
              <path
                d="M4.40625 8.3125H21.5938M4.40625 13H21.5938M4.40625 17.6875H21.5938"
                stroke={`${backgroundColor === 'bg-white' ? '#0E0E0E' : '#FFFFFF'}`}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
            </svg>
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay />
          <Dialog.Content className="fixed bottom-0 left-0 right-0 top-0 z-50 bg-[#0E0E0E]">
            <div className="flex flex-col gap-10 p-6">
              <div className="flex justify-between">
                <Image src={LogoTP.src} alt="Logo TotalPass" width={152} height={44} />
                <Dialog.Close asChild>
                  <button className="IconButton rounded bg-white p-[6px]" aria-label="Fechar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <path
                        d="M24 8L8 24"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 8L24 24"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </Dialog.Close>
              </div>
              <div className="flex flex-col gap-10">
                {header.map((item, index) => {
                  if (item.slug === 'signin')
                    return (
                      <div key={index}>
                        {'items' in item && (
                          <Collapsible.Root>
                            <Collapsible.Trigger asChild>
                              <button className="flex w-full items-center gap-2 text-sm font-medium text-white lg:hidden">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M13.3333 14V12.6667C13.3333 11.9594 13.0524 11.2811 12.5523 10.781C12.0522 10.281 11.3739 10 10.6667 10H5.33332C4.62608 10 3.9478 10.281 3.4477 10.781C2.94761 11.2811 2.66666 11.9594 2.66666 12.6667V14"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M8.00001 7.33333C9.47277 7.33333 10.6667 6.13943 10.6667 4.66667C10.6667 3.19391 9.47277 2 8.00001 2C6.52725 2 5.33334 3.19391 5.33334 4.66667C5.33334 6.13943 6.52725 7.33333 8.00001 7.33333Z"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <span>{item.title}</span>
                              </button>
                            </Collapsible.Trigger>
                            <Collapsible.Content className="px-3 py-2 text-xs text-white">
                              {item.items?.map((item, index) => (
                                <ul className="mb-2 last:mb-0" key={index}>
                                  <Link
                                    href={item.url}
                                    rel={item.rel}
                                    onClick={() =>
                                      handleMenuItemClick({
                                        url: item.url,
                                      })
                                    }
                                  >
                                    <li>{item.title}</li>
                                  </Link>
                                </ul>
                              ))}
                            </Collapsible.Content>
                          </Collapsible.Root>
                        )}
                      </div>
                    );
                })}
                {institutionalSection &&
                  institutionalSection['institutional'].map((item, index) => (
                    <ul className="text-sm text-white" key={index}>
                      <Link
                        href={item.url}
                        rel={item.rel}
                        onClick={() =>
                          handleMenuItemClick({
                            url: item.url,
                          })
                        }
                      >
                        <li>{item.title}</li>
                      </Link>
                    </ul>
                  ))}
                {header.map((item, index) => {
                  if (item.slug === 'aboutTotalpass')
                    return (
                      <div key={index}>
                        {'items' in item && (
                          <Collapsible.Root className="CollapsibleRoot">
                            <Collapsible.Trigger className="group flex w-full select-none items-center gap-2 text-sm leading-none text-white outline-none">
                              <button className="flex w-full items-center justify-between lg:hidden">
                                <span>{item.title}</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                  className="relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
                                >
                                  <path
                                    d="M1.90234 1.89062L6.12109 6.10938L10.3398 1.89062"
                                    stroke="#FFFFFF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </Collapsible.Trigger>
                            <Collapsible.Content className="px-3 py-2 text-xs text-white">
                              {item.items?.map((item, index) => (
                                <ul className="mb-2 last:mb-0" key={index}>
                                  <Link
                                    href={item.url}
                                    rel={item.rel}
                                    onClick={() =>
                                      handleMenuItemClick({
                                        url: item.url,
                                      })
                                    }
                                  >
                                    <li>{item.title}</li>
                                  </Link>
                                </ul>
                              ))}
                            </Collapsible.Content>
                          </Collapsible.Root>
                        )}
                      </div>
                    );
                })}
                {helpSection &&
                  helpSection['help'].map((item, index) => (
                    <ul className="text-sm text-white" key={index}>
                      <Link
                        href={item.url}
                        rel={item.rel}
                        onClick={() =>
                          handleMenuItemClick({
                            url: item.url,
                          })
                        }
                      >
                        <li>{item.title}</li>
                      </Link>
                    </ul>
                  ))}
                <LanguageSwitcher backgroundColor="" language={locale} />
              </div>
              <div className="bottom-10 left-0 right-0 flex flex-col bg-[#0E0E0E] py-6">
                <p className="py-3 text-sm text-white">
                  {locale === 'MX' ? 'Descarga nuestra aplicación' : 'Baixe nosso aplicativo'}
                </p>
                <div className="flex space-x-2">
                  <a
                    href={
                      locale === 'MX'
                        ? 'https://apps.apple.com/mx/app/totalpass/id1506830649'
                        : 'https://apps.apple.com/br/app/totalpass/id1506830649'
                    }
                    target="blank"
                    title="Apple Store"
                  >
                    <Image src={AppleStore.src} alt="Apple Store" width={139} height={41} />
                  </a>
                  <a
                    href={
                      locale === 'MX'
                        ? 'https://play.google.com/store/apps/details?id=com.totalpassmobile&hl=es-MX'
                        : 'https://play.google.com/store/apps/details?id=com.totalpassmobile'
                    }
                    target="blank"
                    title="Google Play"
                  >
                    <Image src={GooglePlay.src} alt="Google Play" width={139} height={41} />
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
